import { ServicesLogo, UserList } from "../../assets/svg";
import ManagerIcon from "../../assets/images/icon-image/manager-list-icon.png";

const navMenu = [
  {
    to: "/home",
    icon: UserList,
    activeIcon: UserList,
    id: "/home",
    label: "User List",
    right: "Users",
  },

  // {
  //   to: "/services",
  //   icon: ServicesLogo,
  //   activeIcon: ServicesLogo,
  //   id: "/services",
  //   label: "Services",
  //   right: "Services",
  // },
  {
    to: "/professional",
    icon: UserList,
    activeIcon: UserList,
    id: "/professional",
    label: "Professional List",
    right: "ProfessionalList",
  },
  {
    to: "/manager",
    icon: ManagerIcon,
    activeIcon: ManagerIcon,
    iconType: "png",
    id: "/manager",
    label: "Manager List",
    right: "ManagerList",
  },
];
export default navMenu;
