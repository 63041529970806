import React from "react";
import { Layout } from "antd";
import { useLocation } from "react-router-dom";
import { useSize } from "../../windowSize";
import "./index.css";

const AppLayout = ({ children }) => {
  const location = useLocation();
  const { innerWidth } = useSize();
  return (
    <Layout
      className={
        location.pathname !== "/auth/login" ? "auth-layout" : "home-scren"
      }
      style={
        location.pathname !== "/auth/login"
          ? { height: "100vh" }
          : innerWidth > 925
          ? { height: "680px" }
          : innerWidth > 805
          ? { height: "570px" }
          : innerWidth > 766
          ? { height: "550px" }
          : innerWidth > 665
          ? { height: "630px" }
          : innerWidth > 550
          ? { height: "680px" }
          : innerWidth > 450
          ? { height: "730px" }
          : { height: "830px" }
      }
    >
      {children}
    </Layout>
  );
};

export default AppLayout;
