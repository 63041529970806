import React, { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { fetchUserInfo } from "../../services/commonServices";
import { isAuthenticated } from "../../utils/authUtil";
import {
  GENESIS_ADMIN_EMAIL,
  GENESIS_ADMIN_SIGNUP,
  GENESIS_ADMIN_TOKEN,
} from "../../utils/constants/appConfigs";
import history from "../../utils/history";
import { removeState, loadState } from "../../utils/storageUtil";
import * as reducerToolsAction from "../../actions/reducerToolsAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AuthContext = createContext({
  user: {},
  isAuthenticated: false,
});

const AuthProvider = (props) => {
  const history = useHistory();
  const [user, setUser] = useState({});
  const [loginLoading, setLoginLoading] = useState(false);
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [authenticated] = useState(isAuthenticated() || false);
  const [allowedMenuList, setAllowedMenuList] = useState([]);
  const userToken = loadState(GENESIS_ADMIN_TOKEN);
  const isSignUp = loadState(GENESIS_ADMIN_SIGNUP);
  const state = {
    user,
    setUser,
    loginLoading,
    signUpLoading,
    authenticated,
    setLoginLoading,
    setSignUpLoading,
    message,
    setMessage,
  };

  const pathname = useLocation().pathname;

  const handleLogin = ({ username, password }) => {};

  const handleLoginWithPopup = () => {};

  const handleSignUp = ({ username, password, displayName }) => {};

  const logout = () => {
    removeState(GENESIS_ADMIN_TOKEN);
    removeState(GENESIS_ADMIN_EMAIL);
    localStorage.removeItem("GenesisNavigation");
    setUser(null);
    history.push(`/auth/login`);
  };
  useEffect(() => {
    if (userToken) {
      fetchUserInfo(userToken)
        .then((res) => {
          if (res?.data?.type === "logout") {
            removeState(GENESIS_ADMIN_TOKEN);
            localStorage.removeItem("GenesisNavigation");
            setUser(null);
            !pathname.includes("template") &&
              !pathname.includes("auth") &&
              history.push(`/auth/login`);
          } else {
            setUser(res.data);
          }
        })
        .catch((err) => {
          if (err?.data?.message === "jwt expired") {
            removeState(GENESIS_ADMIN_TOKEN);
            localStorage.removeItem("GenesisNavigation");
            setUser(null);
            !pathname.includes("template") &&
              !pathname.includes("auth") &&
              history.push(`/auth/login`);
          } else {
            removeState(GENESIS_ADMIN_TOKEN);
            localStorage.removeItem("GenesisNavigation");
            setUser(null);
            !pathname.includes("template") &&
              !pathname.includes("auth") &&
              history.push(`/auth/login`);
          }
        });
    }
    if (pathname.includes("auth")) {
      props.actions.packageCleanRequest();
    }
  }, [pathname, props.actions, isSignUp]);

  return (
    <AuthContext.Provider
      {...props}
      value={{
        ...state,
        handleLogin: handleLogin,
        handleLoginWithPopup: handleLoginWithPopup,
        handleSignUp: handleSignUp,
        logout: logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

/**
 * Map the state to props.
 */
const mapStateToProps = (state) => ({});

/**
 * Map the actions to props.
 */
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign({}, reducerToolsAction),
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider);

export { AuthContext };
