import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";

import history from "../utils/history";
import { signInReducer } from "./authReducer";
import { userByIdReducer, userListReducer } from "./userReducer";
import { professionalReducer } from "./professionalReducer";
import { servicesReducer } from "./serviceReducer";
import { managerListReducer } from "./managerReducer";

/**
 * combine all the reducers here to create state that will be
 * available all the components
 */
const appReducer = combineReducers({
  router: connectRouter(history),
  currentUser: signInReducer,
  userList: userListReducer,
  professionalList: professionalReducer,
  servicesList: servicesReducer,
  managerList: managerListReducer,
  userById: userByIdReducer,
});

const rootReducer = (state, action) => {
  if (action && action.type === "SIGN_OUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
