import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Popover } from "antd";

const NavMenuItem = (props) => {
  const [toggleOpen, setToggleOpen] = useState(true);

  let pathname = useLocation().pathname;
  useEffect(() => {
    if (!props.subMenu) {
      if (pathname.includes(props.id)) {
        props.setActive(props.id);
      }
    } else {
      props.subMenu.forEach((sub) => {
        if (pathname.includes(sub.id)) {
          props.setActive(sub.id);
          if (toggleOpen) {
            props.setOpen(props.id);
          }
        }
      });
    }
  }, [pathname, props, toggleOpen]);

  const PopeoverWrapper = (localProps) => {
    return props.collapsed ? (
      <Popover content={localProps.content} placement="right">
        {localProps.children}
      </Popover>
    ) : (
      localProps.children
    );
  };

  const isAllowed = (menu) => {
    if (menu?.subMenu) {
      let temp = menu.subMenu
        .map((e) => props.allowedMenuList.includes(e.right))
        .filter((item) => item);
      return temp.length > 0;
    } else {
      return (
        props.allowedMenuList?.includes(menu.right) ||
        menu.label === "User List" ||
        menu.label === "Professional List" ||
        menu.label === "Services" ||
        menu.label === "Manager List" ||
        menu.label === "Payment Plan" ||
        menu.label === "Logout"
      );
    }
  };

  return isAllowed(props.menu) ? (
    <div>
      <Link to={props.to}>
        <PopeoverWrapper
          content={<b style={{ color: "#006595" }}>{props.label}</b>}
        >
          <div
            onClick={() => {
              if (!props.subMenu) {
                props.setOpen(false);
                props.setActive(props.id);
              } else if (props?.label === "Logout") {
                props.logout();
              } else {
                if (props.open === props.id) {
                  props.setOpen(false);
                  setToggleOpen(false);
                } else {
                  setToggleOpen(true);
                  props.setOpen(props.id);
                }
              }
            }}
            className={`a-c nav-menu-item ${
              props.active === props.id ? "active" : ""
            } ${props.collapsed ? "collapsed-menu" : ""}`}
          >
            <span
              className={
                props?.active === props.id
                  ? "nav-menu-label-active"
                  : "nav-menu-label"
              }
            >
              {props.icon instanceof Function ? (
                props.active !== props.id ? (
                  props.icon()
                ) : (
                  props.activeIcon()
                )
              ) : props?.iconType === "png" ? (
                <img
                  src={
                    props.active !== props.id ? props.icon : props.activeIcon
                  }
                  className={"nav-menu-image"}
                  alt={"menu"}
                />
              ) : props.active !== props.id ? (
                props.icon
              ) : (
                props.activeIcon
              )}{" "}
              <span style={{ marginLeft: 4 }}>{props.label}</span>
            </span>
          </div>
        </PopeoverWrapper>
      </Link>
    </div>
  ) : null;
};

export default NavMenuItem;
