import {
  MANAGER_LIST,
  MANAGER_LIST_SUCCESS,
  MANAGER_LIST_FAILURE,
} from "../utils/constants/actionTypes";

const INITIAL_STATE = {
  payload: [],
  loading: false,
  errors: {},
  pagination: {},
};

/**
 * A reducer takes two arguments, the current state and an action.
 */
export const managerListReducer = (state, action) => {
  state = state || INITIAL_STATE;
  switch (action?.type) {
    case MANAGER_LIST:
      return Object.assign({}, state, {
        loading: true,
      });
    case MANAGER_LIST_SUCCESS:
      return Object.assign({}, state, {
        payload: action.data,
        loading: false,
        errors: {},
      });

    case MANAGER_LIST_FAILURE:
      return Object.assign({}, state, {
        payload: [],
        loading: false,
        errors: action.error,
      });

    default:
      return state;
  }
};
