export const GenesisLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="144"
    height="25"
    viewBox="0 0 144 25"
    fill="none"
  >
    <g clip-path="url(#clip0_385_7747)">
      <mask
        id="mask0_385_7747"
        maskUnits="userSpaceOnUse"
        x="138"
        y="0"
        width="6"
        height="5"
      >
        <path
          d="M140.28 2.16824H141.05C141.318 2.16824 141.354 2.34863 141.354 2.44083C141.354 2.54105 141.318 2.72144 141.05 2.72144H140.28V2.16824ZM139.699 1.75535V3.80378H140.284V3.13433H140.965L141.382 3.80378H142.016L141.539 3.05817C141.795 2.95795 141.951 2.76153 141.951 2.44484C141.951 2.02794 141.643 1.75936 141.074 1.75936H139.699V1.75535ZM138.873 2.80562C138.873 1.75535 139.727 0.969644 140.765 0.969644C141.795 0.969644 142.649 1.75535 142.649 2.80562C142.649 3.84788 141.795 4.63759 140.765 4.63759C139.727 4.63759 138.873 3.84788 138.873 2.80562ZM138.48 2.80562C138.48 4.04831 139.506 4.97833 140.765 4.97833C142.016 4.97833 143.042 4.04831 143.042 2.80562C143.042 1.56293 142.016 0.628906 140.765 0.628906C139.506 0.628906 138.48 1.56293 138.48 2.80562Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_385_7747)">
        <path
          d="M143.042 0.628906H138.48V4.97833H143.042V0.628906Z"
          fill="url(#paint0_linear_385_7747)"
        />
      </g>
      <mask
        id="mask1_385_7747"
        maskUnits="userSpaceOnUse"
        x="33"
        y="0"
        width="18"
        height="16"
      >
        <path
          d="M33.9053 7.96818C33.9053 13.1875 37.8137 15.4163 42.3957 15.4163C45.0133 15.4163 47.0618 14.5585 48.2884 12.8788L48.6773 15.1317H50.417V6.98605H42.4157V9.31911H47.3264C47.1219 10.8344 45.7109 12.9229 42.4157 12.9229C38.9001 12.9229 37.2806 10.7101 37.2806 7.96818C37.2806 5.2062 38.836 2.99741 42.2514 2.99741C44.7047 2.99741 46.0556 3.87932 46.6729 5.18615H50.1525C49.1503 1.81084 45.9193 0.5 42.2554 0.5C38.1224 0.5 33.9053 2.72883 33.9053 7.96818Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_385_7747)">
        <path
          d="M50.4211 0.5H33.9053V15.4163H50.4211V0.5Z"
          fill="url(#paint1_linear_385_7747)"
        />
      </g>
      <mask
        id="mask2_385_7747"
        maskUnits="userSpaceOnUse"
        x="52"
        y="0"
        width="14"
        height="16"
      >
        <path
          d="M52.6699 15.1316H65.4576V12.5941H52.6699V15.1316ZM52.6699 9.03442H64.6799V6.51696H52.6699V9.03442ZM52.6699 3.32204H65.2532V0.808594H52.6699V3.32204Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_385_7747)">
        <path
          d="M65.4576 0.808594H52.6699V15.1316H65.4576V0.808594Z"
          fill="url(#paint2_linear_385_7747)"
        />
      </g>
      <mask
        id="mask3_385_7747"
        maskUnits="userSpaceOnUse"
        x="67"
        y="0"
        width="16"
        height="16"
      >
        <path
          d="M79.3361 0.808594V11.0588L71.3949 0.808594H67.7109V15.1316H71.0061V4.89745L78.9473 15.1316H82.6513V0.808594H79.3361Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_385_7747)">
        <path
          d="M82.6473 0.808594H67.7109V15.1316H82.6473V0.808594Z"
          fill="url(#paint3_linear_385_7747)"
        />
      </g>
      <mask
        id="mask4_385_7747"
        maskUnits="userSpaceOnUse"
        x="85"
        y="0"
        width="14"
        height="16"
      >
        <path
          d="M85.5132 15.1316H98.3009V12.5941H85.5132V15.1316ZM85.5132 9.03442H97.5232V6.51696H85.5132V9.03442ZM85.5132 3.32204H98.0964V0.808594H85.5132V3.32204Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask4_385_7747)">
        <path
          d="M98.3009 0.808594H85.5132V15.1316H98.3009V0.808594Z"
          fill="url(#paint4_linear_385_7747)"
        />
      </g>
      <mask
        id="mask5_385_7747"
        maskUnits="userSpaceOnUse"
        x="99"
        y="0"
        width="16"
        height="16"
      >
        <path
          d="M99.9804 5.04143C99.9804 7.2903 101.207 8.48088 105.116 9.1343C109.024 9.78771 111.233 9.64741 111.233 11.1186C111.233 12.2009 110.086 12.9185 107.364 12.9185C104.767 12.9185 103.047 12.1609 102.963 10.6656H99.5234C99.5234 13.5719 102.121 15.4319 107.076 15.4319C112.171 15.4319 114.728 13.7764 114.728 10.6656C114.728 7.4747 111.986 7.14599 108.038 6.59279C105.52 6.24404 103.147 6.16386 103.147 4.81294C103.147 3.83081 103.845 3.03308 106.791 3.03308C109.433 3.03308 110.619 3.95508 110.74 4.97729H113.931C113.85 2.43979 111.886 0.515625 106.831 0.515625C101.576 0.519634 99.9804 2.68833 99.9804 5.04143Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask5_385_7747)">
        <path
          d="M114.736 0.519531H99.5312V15.4358H114.736V0.519531Z"
          fill="url(#paint5_linear_385_7747)"
        />
      </g>
      <mask
        id="mask6_385_7747"
        maskUnits="userSpaceOnUse"
        x="116"
        y="0"
        width="5"
        height="16"
      >
        <path
          d="M120.076 0.804688H116.781V15.1277H120.076V0.804688Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask6_385_7747)">
        <path
          d="M120.076 0.804688H116.781V15.1277H120.076V0.804688Z"
          fill="url(#paint6_linear_385_7747)"
        />
      </g>
      <mask
        id="mask7_385_7747"
        maskUnits="userSpaceOnUse"
        x="121"
        y="0"
        width="17"
        height="16"
      >
        <path
          d="M122.369 5.04143C122.369 7.2903 123.596 8.48088 127.504 9.1343C131.413 9.78771 133.621 9.64741 133.621 11.1186C133.621 12.2009 132.475 12.9185 129.753 12.9185C127.155 12.9185 125.436 12.1609 125.352 10.6656H121.912C121.912 13.5719 124.51 15.4319 129.464 15.4319C134.56 15.4319 137.117 13.7764 137.117 10.6656C137.117 7.4747 134.375 7.14599 130.427 6.59279C127.909 6.24404 125.536 6.16386 125.536 4.81294C125.536 3.83081 126.233 3.03308 129.18 3.03308C131.818 3.03308 133.008 3.95508 133.128 4.97729H136.319C136.239 2.43979 134.271 0.515625 129.22 0.515625C123.965 0.519634 122.369 2.68833 122.369 5.04143Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask7_385_7747)">
        <path
          d="M137.125 0.519531H121.92V15.4358H137.125V0.519531Z"
          fill="url(#paint7_linear_385_7747)"
        />
      </g>
      <mask
        id="mask8_385_7747"
        maskUnits="userSpaceOnUse"
        x="35"
        y="17"
        width="7"
        height="8"
      >
        <path
          d="M35.6652 19.7908C35.6652 21.1096 36.6433 21.3061 38.6597 21.5586C40.0066 21.731 40.7202 21.8192 40.7202 22.681C40.7202 23.595 39.6979 23.9277 38.5154 23.9277C37.4491 23.9277 36.1222 23.7233 36.05 22.6369H35.4487C35.5008 23.8476 36.6233 24.501 38.5154 24.501C40.3995 24.501 41.3415 23.7473 41.3415 22.6369C41.3415 21.3822 40.4636 21.1938 38.7599 20.9773C37.1564 20.7729 36.2745 20.7007 36.2745 19.7948C36.2745 18.8728 37.1604 18.5481 38.2909 18.5481C39.4574 18.5481 40.2792 18.8367 40.3794 19.7146H40.9807C40.9005 18.4519 39.7982 17.9668 38.2909 17.9668C36.8237 17.9628 35.6652 18.5641 35.6652 19.7908Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask8_385_7747)">
        <path
          d="M41.3414 17.9629H35.4526V24.497H41.3414V17.9629Z"
          fill="url(#paint8_linear_385_7747)"
        />
      </g>
      <mask
        id="mask9_385_7747"
        maskUnits="userSpaceOnUse"
        x="55"
        y="18"
        width="6"
        height="7"
      >
        <path
          d="M55.2397 18.0977V18.6869H57.6169V24.3712H58.2062V18.6869H60.5833V18.0977H55.2397Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask9_385_7747)">
        <path
          d="M60.5833 18.0977H55.2397V24.3753H60.5833V18.0977Z"
          fill="url(#paint9_linear_385_7747)"
        />
      </g>
      <mask
        id="mask10_385_7747"
        maskUnits="userSpaceOnUse"
        x="75"
        y="18"
        width="6"
        height="7"
      >
        <path
          d="M80.0412 18.0977V21.7897C80.0412 23.2769 79.4038 23.9223 77.9166 23.9223C76.4294 23.9223 75.792 23.2769 75.792 21.7897V18.0977H75.1987V21.7897C75.1987 23.8181 76.2931 24.4995 77.9166 24.4995C79.5401 24.4995 80.6425 23.8181 80.6425 21.7897V18.0977H80.0412Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask10_385_7747)">
        <path
          d="M80.6425 18.0977H75.1987V24.4995H80.6425V18.0977Z"
          fill="url(#paint10_linear_385_7747)"
        />
      </g>
      <mask
        id="mask11_385_7747"
        maskUnits="userSpaceOnUse"
        x="95"
        y="18"
        width="7"
        height="7"
      >
        <path
          d="M96.2964 18.6869H98.0001C100.177 18.6869 100.959 19.637 100.959 21.2324C100.959 22.8279 100.177 23.7699 98.0001 23.7699H96.2964V18.6869ZM95.7031 18.0977V24.3753H98.0001C100.249 24.3753 101.568 23.3971 101.568 21.2365C101.568 19.0758 100.249 18.0977 98.0001 18.0977H95.7031Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask11_385_7747)">
        <path
          d="M101.568 18.0977H95.7031V24.3753H101.568V18.0977Z"
          fill="url(#paint11_linear_385_7747)"
        />
      </g>
      <mask
        id="mask12_385_7747"
        maskUnits="userSpaceOnUse"
        x="116"
        y="18"
        width="1"
        height="7"
      >
        <path
          d="M116.785 18.0977H116.184V24.3753H116.785V18.0977Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask12_385_7747)">
        <path
          d="M116.785 18.0977H116.184V24.3753H116.785V18.0977Z"
          fill="url(#paint12_linear_385_7747)"
        />
      </g>
      <mask
        id="mask13_385_7747"
        maskUnits="userSpaceOnUse"
        x="131"
        y="17"
        width="8"
        height="8"
      >
        <path
          d="M132.018 21.232C132.018 19.6366 133.068 18.5422 134.796 18.5422C136.52 18.5422 137.578 19.6366 137.578 21.232C137.578 22.8275 136.52 23.9219 134.796 23.9219C133.068 23.9219 132.018 22.8315 132.018 21.232ZM131.401 21.232C131.401 23.3927 132.996 24.4951 134.8 24.4951C136.592 24.4951 138.192 23.3927 138.192 21.232C138.192 19.0713 136.596 17.9609 134.8 17.9609C132.996 17.9609 131.401 19.0713 131.401 21.232Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask13_385_7747)">
        <path
          d="M138.188 17.9609H131.401V24.4951H138.188V17.9609Z"
          fill="url(#paint13_linear_385_7747)"
        />
      </g>
      <mask
        id="mask14_385_7747"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="30"
        height="25"
      >
        <path
          d="M0 24.3117H29.3476V18.4911H0V24.3117ZM0 15.3884H27.5637V9.61187H0V15.3884ZM0 6.5773H29.3476V0.800781H0V6.5773Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask14_385_7747)">
        <path
          d="M29.3476 0.800781H0V24.3117H29.3476V0.800781Z"
          fill="url(#paint14_linear_385_7747)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_385_7747"
        x1="-4.63553"
        y1="2.80691"
        x2="143.627"
        y2="2.80691"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5554A4" />
        <stop offset="1" stopColor="#73C9BD" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_385_7747"
        x1="-4.63598"
        y1="7.96121"
        x2="143.627"
        y2="7.96121"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5554A4" />
        <stop offset="1" stopColor="#73C9BD" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_385_7747"
        x1="-4.63599"
        y1="7.97364"
        x2="143.627"
        y2="7.97364"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5554A4" />
        <stop offset="1" stopColor="#73C9BD" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_385_7747"
        x1="-4.63556"
        y1="7.97364"
        x2="143.628"
        y2="7.97364"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5554A4" />
        <stop offset="1" stopColor="#73C9BD" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_385_7747"
        x1="-4.63589"
        y1="7.97364"
        x2="143.627"
        y2="7.97364"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5554A4" />
        <stop offset="1" stopColor="#73C9BD" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_385_7747"
        x1="-4.63623"
        y1="7.97946"
        x2="143.627"
        y2="7.97946"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5554A4" />
        <stop offset="1" stopColor="#73C9BD" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_385_7747"
        x1="-4.63561"
        y1="7.97374"
        x2="143.628"
        y2="7.97374"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5554A4" />
        <stop offset="1" stopColor="#73C9BD" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_385_7747"
        x1="-4.63602"
        y1="7.97946"
        x2="143.627"
        y2="7.97946"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5554A4" />
        <stop offset="1" stopColor="#73C9BD" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_385_7747"
        x1="-4.63597"
        y1="21.2347"
        x2="143.627"
        y2="21.2347"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5554A4" />
        <stop offset="1" stopColor="#73C9BD" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_385_7747"
        x1="-4.63575"
        y1="21.2395"
        x2="143.628"
        y2="21.2395"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5554A4" />
        <stop offset="1" stopColor="#73C9BD" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_385_7747"
        x1="-4.63602"
        y1="21.3021"
        x2="143.627"
        y2="21.3021"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5554A4" />
        <stop offset="1" stopColor="#73C9BD" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_385_7747"
        x1="-4.63598"
        y1="21.2395"
        x2="143.627"
        y2="21.2395"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5554A4" />
        <stop offset="1" stopColor="#73C9BD" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_385_7747"
        x1="-4.63595"
        y1="21.2395"
        x2="143.627"
        y2="21.2395"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5554A4" />
        <stop offset="1" stopColor="#73C9BD" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_385_7747"
        x1="-4.63556"
        y1="21.2328"
        x2="143.628"
        y2="21.2328"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5554A4" />
        <stop offset="1" stopColor="#73C9BD" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_385_7747"
        x1="-4.6358"
        y1="12.5588"
        x2="143.627"
        y2="12.5588"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5554A4" />
        <stop offset="1" stopColor="#73C9BD" />
      </linearGradient>
      <clipPath id="clip0_385_7747">
        <rect
          width="143.042"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
