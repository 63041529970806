import { ConnectedRouter } from "connected-react-router";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import configureStore from "./store/configureStore";

// import "antd/dist/antd.css";
import "antd/dist/antd.min.css";

import "./assets/css/icons.css";
import "./index.css";
import App from "./pages/App";
import "./styles/normalize.css";
import history from "./utils/history";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AuthProvider from "./components/Context/AuthContext";
import * as serviceWorker from "./serviceWorker";

const mountNode = document.getElementById("root");
const store = configureStore();
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

ReactDOM.render(
  <Elements stripe={stripePromise}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Router basename={process.env.PUBLIC_URL} history={history}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </Router>
      </ConnectedRouter>
    </Provider>
  </Elements>,
  mountNode
);

serviceWorker.unregister();
