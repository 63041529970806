import React from "react";
import AuthLayout from "../../components/AuthLayout";
import Layout from "../../components/Layout";

/**
 * Dynamically import component by there folder name
 * @param {string} importString
 * @returns Lazy loaded component
 */
const asyncComponent = (importString) => {
  return React.lazy(() => import(`../${importString}`));
};

/**
 * First element is layout component that wraps privated component
 * Second element is layout compoent that wraps authantication component
 * Third element is list of components with there respective routes and nature of there authentication.
 */
const AsyncComponent = [
  Layout,
  AuthLayout,
  [
    [asyncComponent("Auth"), ["auth", "", "/"], "restricted"],
    [asyncComponent("Home"), ["home"], "private"],
    [asyncComponent("Professional"), ["professional"], "private"],
    [asyncComponent("Manager"), ["manager"], "private"],
  ],
];

export default AsyncComponent;
