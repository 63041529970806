export const REDUCER_CLEAN_REQUEST = "REDUCER_CLEAN_REQUEST";
export const PACKAGE_CLEAN_REQUEST = "PACKAGE_CLEAN_REQUEST";

export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";
export const SIGN_OUT = "SIGN_OUT";

export const USER_LIST = "USER_LIST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAILURE = "USER_LIST_FAILURE";

export const USER_BY_ID = "USER_BY_ID";
export const USER_BY_ID_SUCCESS = "USER_BY_ID_SUCCESS";
export const USER_BY_ID_FAILURE = "USER_BY_ID_FAILURE";

export const PROFESSIONAL_LIST = "PROFESSIONAL_LIST";
export const PROFESSIONAL_LIST_SUCCESS = "PROFESSIONAL_LIST_SUCCESS";
export const PROFESSIONAL_LIST_FAILURE = "PROFESSIONAL_LIST_FAILURE";

export const SERVICES_LIST = "SERVICES_LIST";
export const SERVICES_LIST_SUCCESS = "SERVICES_LIST_SUCCESS";
export const SERVICES_LIST_FAILURE = "SERVICES_LIST_FAILURE";

export const MANAGER_LIST = "MANAGER_LIST";
export const MANAGER_LIST_SUCCESS = "MANAGER_LIST_SUCCESS";
export const MANAGER_LIST_FAILURE = "MANAGER_LIST_FAILURE";
